.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}
.color-w{
	color: #fff;
}
.homepage__buttons {
	padding: 20px 0;
}
.title-first{
	padding-top: 60px;
	font-weight: bold;
	font-size: 45px;
	line-height: 0.8;
}
.title-second{
	font-size: 40px;
	line-height: 0.8;
}
.homepage-content{
	padding-top: 60px;
	max-width: 856px;
}
.link-more{
	padding-top: 60px;
	font-size: 20px;
	padding-bottom: 10px;
	
}
.span-link{
	font-weight: bold;
	border-bottom: 1px solid #a5742f;
}
.h-link1{
	font-weight: 600;
	font-size: 25px;
}
.h-link3{
	position: relative;
	font-size: 25px;
	font-weight: 600;
}
.h-link3:after{
	content: '';
	position: absolute;
	left: 0;	
	right: 0;
	bottom: -25px;
	margin: 0 auto;
	width: 60px;
	height: 4px;
	background: #a5742f;
	
}
.center{
	text-align: center;	
}
.h-link2{
	font-size: 30px;
	font-weight: 900;
	color: #a5742f;
}
.mg-top60{
	margin-top: 60px;
	
}
.mg-top90{
	margin-top: 90px;
}
.mg-top100{
	margin-top: 90px;
	@include respond-to('medium') {
		margin-top: 30px;
	}
}
.h-link1.center.mg-top60 {
	@include respond-to('medium') {
			padding-top: 70px;
	}
}



.no-link{
	color: #000;
	text-decoration: none;
} 
.no-link:focus , .no-link:hover{ 
	color: #000;
	text-decoration: none;
}
.mg-top180{
	margin-top:180px;
	@include respond-to('medium') {
		margin-top:30px;
	} 
}
.baground-first{
	height: 580px; 
	background-position: center;
	@include respond-to('medium') {
		height: auto;
		background-repeat: repeat;
	}
}
.h-link2, .h-link1 , .h-link3 {
	line-height: 0.9;
	@include respond-to('medium') {
		line-height: 1;
	} 
}
.fith{
	height: 247px;
	@include respond-to('medium') {
		height: 110px;
	} 
}
.no-link{
	text-decoration: none;
} 
.no-link:focus , .no-link:hover{ 
	text-decoration: none;
}
