.wrap-content {
	padding: 20px 0;
}

.wrap-all-content-not-banner {
	margin-top: 140px;
}

.title {
	font-size: 45px;
	font-weight: 900;
	line-height: 1;
	color: #dfac4e;
	margin: 30px 0 30px;
}
.title span{
	font-size: 40px;
	font-weight: 400;
}

.wrap-all-content-not-banner{
  margin-top: 180px;
  @include respond-to('medium'){
    margin-top: 80px;
  }
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 300;
		line-height: 1.2;
		color: #000;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: #000;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}