footer {
	width: 100%;
	position: relative;
	background-color: #fff;
	z-index: 1;
	padding: 30px 0 15px;
	box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.1);
	@include respond-to('extra-small') {
		padding: 20px 0 5px;
	}
}

.logo__ftr {
	text-align: center;
	& a {
		display: inline-block;
		& img {}
	}
}

.logo-ftr-1 {
	margin-#{$left}: 25px;
	& img {
		max-width: 195px;
	}
}

.logo-ftr-2 {
	margin-#{$left}: 10px;
	& img {
		max-width: 84px
	}
}

.logo-ftr-3 {
	& img {
		max-width: 138px
	}
}

.ftr-contact {
	text-align: center;
	padding: 25px 0 25px;
	@include respond-to('extra-small') {
		padding: 25px 0 15px;
	}
	@include respond-to('mobile-m') {
		padding: 15px 0;
	}
	& ul {
		display: inline-block;
		margin: 0;
		padding: 0;
		list-style: none;
		& i{
			color: #dfac4e;
		}
		@include respond-to('extra-small') {
			max-width: 290px;
		}
		& li {
			float: $right;
			margin-#{$left}: 50px;
			&:last-child {
				margin-#{$left}: 0;
			}
			@include respond-to('medium') {
				width: 50%;
				margin-#{$left}: 0;
				text-align: right;
			}
			@include respond-to('extra-small') {
				width: 100%;
			}
			& a,
			div {
				font-size: 18px;
				font-weight: 700;
				@include link_no-hover(#000);
				& span {
					display: inline-block;
					& i {
						margin-#{$left}: 14px;
						font-size: 22px;
						// color: $accent-g;
					}
				}
			}
			& .ltr {
				& span {
					direction: ltr;
				}
			}
		}
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #dedede;
	margin-top: 0;
	margin-bottom: 15px;
}


.ftr-wrap {
	position: relative;
	z-index: 1;
	&__right {
		width: 22%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 250px;
			margin: 0 auto 15px;
			text-align: center;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
	&__left {
		width: 100%;
		float: $left;
		padding-top: 40px;
		@include respond-to('1100') {
			float: none;
			width: 100%;
		}
		@include respond-to('medium') {
			padding-top: 20px;
		}
		@include respond-to('extra-small') {
			padding-top: 10px;
		}
	}
}
 
.link-col {
	float: $right;
	width: 25%;
	@include respond-to('small') {
		width: 50%;
	}
}
