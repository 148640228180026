.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden; 
	@include respond-to('huge') {
		height: 650px;
	}
	@include respond-to('large') {
		height: 450px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}
 
.banner-item {
	width: 100%;
	min-height: 100vh; 
	position: relative;
	background-repeat: no-repeat;
	background-position: top;
	background-size: inherit; 
	@include respond-to('huge') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
		background-size: contain;
		background-position: bottom;
	}
}

.relativ{
	position: relative;
}

.banner-item__title {
    position: absolute;
    z-index: 1;
    top: 60vh;
    right: 0!important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 360px;
    width: 100%;
	padding: 0 15px;
	font-family: Hazilim;
    color: #a5742f;
    font-size: 50px;
    font-weight: 400;
	line-height: 1.4;
	@include respond-to('medium') {
		font-size: 20px;
		top: 43vh;
		color: #000;
		text-shadow: 0 0 3px #fff;
	}
}