$arrow-left-slick: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAABFCAYAAACoh90mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQNJREFUeNpiZGBgaGCgEmBioCIYNWzUsFHDRg0bNWzUsFHDhoJhcnJyHN3d3Qa45FlIMej48ePx9+/ffwHkXiDbZTCDjh49etPGxmYj2d6EGbRs2bKTYWFhB8gOM2SDSktLL5AdAYGBgRKkGITTMJBBQENIMghrbFpaWgrMnTs3PD8/f+WsWbMeUJTOnj59+uP79+8/XFxcFChOtI8ePfoBdN1Ca2tr9SNHjvhTnANABkpLS88EsUkxEG/SgCVQYg0kmGhJMZCo7AQz8NatW/GghExxqQEy8O7duy9ACZniUgMEPD09d4LSIS55xtHuzqhho4aNGjZq2Khho4YNA8MAAgwA3tdpal0B9nIAAAAASUVORK5CYII=');

$arrow-right-slick: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAABFCAYAAACoh90mAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAASFJREFUeNpiZGBgaGCgEmBioCIYNWzUsFHDRg0bNWzUsFHDBpth3d3dBnJychxUMczS0lL++PHj8aQYiNMwGxubjUePHr1JioF4wywsLOzAsmXLThJrIAshBaWlpRdANMhAoNcXPnr06AcutcxA7EDIwN27d7+QlJT8O3HiRO/79+8/vXHjxheyDUM2sLOzM/jatWt3sRlItGEwA1+/fv108uTJwYcOHbr55MmTHxQlWhcXF4Xv37//ePr06Q+SIwAZHDlyxF9RUVECV0SwkGIQiJaWlp5JUd6EGQRKyBRldGINwmsYKMXfunUrnliD8BoGSvF37959QaxBIMCIq4cCjDEBoIEfSIltxtHuzqhho4aNGjZq2Khho4YNA8MAAgwAVfF4PSsqFjMAAAAASUVORK5CYII=');

.wrapper-prod-gallery {
	// max-width: 790px;
	width: 100%;
	border: 1px solid #d6d6d6;
	position: relative;
	@include respond-to('large') {
		margin: 0 auto;
	}
	@include respond-to('medium') {
		margin-bottom: 15px;
		max-width: 650px;
	}
	@include respond-to('small') {
		height: auto;
	}
	&_padding {
		padding: 20px;
		@include respond-to('extra-small') {
			padding: 15px;
		}
	}
}

.prod-gallery {
	position: relative;
	width: 100%;
	// height: 580px;
	&_vertical {
		width: 75%;
		float: #{$left};
		@include respond-to('extra-small') {
			width: 100%;
			float: none;
		}
	}
	@include respond-to('small') {
		height: auto;
	}
	&__arrow-left,
	&__arrow-right {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 70px;
		cursor: pointer;
	}
	&__arrow-right {
		#{$right}: 0;
		background: $arrow-right-slick no-repeat;
	}
	&__arrow-left {
		#{$left}: 0;
		background: $arrow-left-slick no-repeat;
	}
	& .slick-next {
		left: 0;
	}
	& .slick-prev {
		right: 0;
	}
	&__img-big {
		width: 100%;
		height: 400px;
		@include respond-to('small') {
			height: 400px;
		}
		@include respond-to('extra-small') {
			height: 270px;
		}
		&_youtube {
			&::before {
				display: none;
				font-family: "Font Awesome 5 Brands";
				content: "\f167";
			}
			&.svg-inline--fa {
				margin-left: 0.25em;
				font-size: 2em;
				vertical-align: middle;
			}
			& .fa-youtube {
				color: $accent;
				font-size: 60px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				cursor: pointer;
			}
		}
		&:hover {
			cursor: zoom-in;
		}
		img {
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

.prod-gallery-navi {
	position: relative;
	width: 100%;
	margin-top: 15px;
	padding: 0 2px;
	&_vertical {
		position: absolute;
		top: 50%;
		#{$right}: 20px;
		transform: translateY(-50%);
		width: 20%;
		margin-top: 0;
		padding: 2px 0;
		@include respond-to('extra-small') {
			position: static;
			width: 100%;
			margin-top: 15px;
			padding: 0 2px;
		}
		& .slick-list {
			margin: -8px 0;
			@include respond-to('extra-small') {
				margin: 0 -8px;
			}
		}
	}
	.slick-list {
		margin: 0 -8px;
	}
	&__img-small {
		width: 150px;
		height: 100px !important;
		position: relative;
		margin: 0 7px;
		border: 1px solid #d6d6d6 !important;
		cursor: pointer;
		transition: all 0.4s;
		&_vertical {
			width: 100px;
			height: 70px !important;
			margin: 0 7px 7px;
			@include respond-to('extra-small') {
				margin: 0 7px;
			}
		}
		&.slick-current {
			border: 1px solid $accent !important;
		}
		&:hover {
			border: 1px solid $accent !important;
		}
		&:focus {
			outline: none;
		}
		&_padding {
			width: 100%;
			height: 100%;
			padding: 5px;
			overflow: hidden;
			position: relative;
			& > img {
				max-width: 90%;
				max-height: 90%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}
		}
		&_youtube {
			&::before {
				font-family: "Font Awesome 5 Brands";
				content: "\f167";
				color: $clr-youtube;
				font-size: 32px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				cursor: pointer;
			}
		}
	}
}

#prod-gallery {
	.prod-gallery__img-big {
		display: none;
	}
	.prod-gallery__img-big:first-child,
	.prod-gallery__img-big.slick-slide {
		display: block;
	}
}

#prod-gallery-navi {
	.prod-gallery-navi__img-small {
		display: none;
	}
	.prod-gallery-navi__img-small:first-child,
	.prod-gallery-navi__img-small.slick-slide {
		display: block;
	}
}

.ribbon-box {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 94px;
	height: 94px;
	& > span {
		font-size: 15px;
		color: #fff;
		text-align: center;
		height: 25px;
		transform: rotate(-45deg);
		width: 115px;
		display: block;
		background: #7292cb;
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 20px;
		left: -25px;
		padding: 1px 15px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #5a5a5a;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #5a5a5a;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-right: 3px solid #5a5a5a;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #5a5a5a;
		}
	}
	&_big {
		width: 147px;
		height: 147px;
		& > span {
			width: 180px;
			background: #0a1322;
			top: 43px;
			left: -34px;
		}
	}
}
