.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;

	// box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.header-desktop {
	pointer-events: none;
	height: 375px;
	background-image: url(../images/header-back.png); 
	background-repeat: no-repeat;
	background-position:  bottom;  
	background-size: cover;
	font-size: 0;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
		pointer-events: all;
	}
}

.logo {
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
	transition: transform 0.1s ease;
}

.main-navi {
	display: inline-block;
	width: 20%;
	vertical-align: top;
	@include respond-to('huge') {
		width: 25%;
	}
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(30px 0 0 0);
		padding: 0;
		& > li:last-child{
			border-#{$left}: none;
		}
		& > li {
			float: right; 
			margin-#{$left}: 15px;
			padding-#{$left}: 15px;
			border-#{$left}: 1px solid #a5742f;
			position: relative;
			&:last-child {
				margin-#{$left}: 0;
			}
			& > a {
				display: block;
				padding: 1px 1px;
				border-bottom: 2px solid transparent;
				font-size: 20px;
				font-weight: 600;
				color: #000;
				transition: border 0.2s ease-in;
				&:hover {
					border-bottom: 2px solid #a5742f;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 1px solid #a5742f;
					font-weight: 400;
				}
			}
		}
	}
}
.main-navi {
	display: inline-block;
	height: 100%;
	& > ul {
		display: inline-block;
		list-style: none;
		//   padding:32px 190px 0 0;
		// margin: 0;
		// height: 100%;
		@include respond-to('huge') {
			// padding: 32px 20px 0 0;
		}
		& > li {
			float: $right;
			position: relative;  
			height: 100%;
			&:hover {
				& > ul {
					display: block;
					opacity: 1;
					transition: 0.4s;
				}
			}
			&:last-child {
				& > span {
					opacity: 0;
					display: none;
				}
			}
			& > a {
				font-size: 20px;
				font-weight: 500; 
				//   color: #002c5c;
				@include respond-to('huge') {
					font-size: 16px;
				}

				@include respond-to('large') {
					font-size: 15px;
				}
			}
			& > ul {
				padding: 0;
				margin: 0;
				list-style: none;
				background-color: #ffffff;
				position: absolute;
				top: 32px;
				#{$right}: 0;
				z-index: 9;
				font-size: 20px;
				opacity: 0;
				display: none;
				transition: 0.4s;
				min-width: 285px; 
				text-align: $right;
				//   border-left: 1px solid #3d5184;
				@include respond-to('large') {
					top: 31px;
				}
				& > li {
					&:hover {
						background-color: #f4f4f4;
					}
					border-bottom: 1px solid #f3f3f3;
					position: relative;
					& > a {
						border-bottom: 1px solid #e1e1e1;
						transition: 0.3s;
						color: #002c5c;
						padding: 7px 12px;
						display: block;
						opacity: 1;
						&:hover {
							text-decoration: none !important;
						}
					}
					& > ul {
						padding: 0;
						margin: 0;
						list-style: none;
						background-color: #ffffff;
						position: absolute;
						top: 0;
						transition: 0.4s;
						left: 285px;
						z-index: 9;
						opacity: 0;
						display: none;
						min-width: 400px;
						text-align: center;
						& > li {
							//border-bottom: 1px solid #3a4f82;
							position: relative;
							&:hover {
								background-color: #f4f4f4;
								text-decoration: none !important;
							}
							& > a {
								&:hover {
									text-decoration: none !important;
								}
								transition: 0.3s;

								color: #002c5c;
								padding: 7px 12px;
								display: block;
								opacity: 1;
								border-bottom: 1px solid #f3f3f3;
								text-align: left;
							}
							& > ul { 
								padding: 0 20px;
								margin: 0;
								list-style: none;
								background-color: #ffffff;
								position: absolute;
								top: 0;
								transition: 0.4s;
								right: 314px;
								z-index: 9;
								opacity: 0;
								display: none;
								min-width: 315px;
								text-align: center;
								& > li {
									position: relative;
									float: right;
									width: 50%;
									&:nth-child(2n+1) {
										clear: both;
									}
									& > a {
										transition: 0.3s;
										color: #2d2a6e;
										padding: 12px 0px;
										display: block;
										opacity: 1;
										&:hover {
											color: #002c5c;
										}
									}
								}
							}
							&:hover {
								& > ul {
									transition: 0.3s;
									display: block;
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						& > ul {
							transition: 0.3s;
							display: block;
							opacity: 1;
						}
					}
				}

			}
		}
	}
} 


.category-navi {
	display: inline-block;
	width: 60%;
	text-align: center;
	@include respond-to('huge') {
		width: 50%;
	}
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(120px -20% 0 -20%);
		padding: 0;
		& > li {
			float: right;
			cursor: pointer;
			padding: 0 10px;
			// position: relative;
			@include respond-to('huge') {
				// margin-#{$left}: 20px;
			}
			@include respond-to('1100') {
				margin-#{$left}: 12px;
			}
			&:last-child {
				margin-#{$left}: 0;
			}
			& > a {
				display: block;
				// border-bottom: 2px solid transparent;
				// padding: 3px 1px;
				font-size: 20px;
				font-weight: 400;
				color: #fff;
				// transition: border 0.2s ease-in;
				@include respond-to('larger') {
					font-size: 18px;
				}
				@include respond-to('1100') {
					font-size: 17px;
				}
				&:hover {
					border-bottom: 2px solid #fff;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 2px solid #fff;
				}
			}
		}
	}
}

.sub-navi {
	display: inline-block;
	width: 20%;
	text-align: $left;
	padding-top: 31px;
	vertical-align: top;
	font-size: initial;
	@include respond-to('huge') {
		width: 25%;
	}
}

.find-last {
	display: inline-block;
	height: 100%;
	max-height: 34px;
	max-width: 215px;
	margin-#{$left}: 35px;
	margin-#{$right}: 15px;
	vertical-align: top;
	@include respond-to('1550') {
		margin-#{$right}: 0;
		margin-#{$left}: 15px;
	}
	@include respond-to('1100') {
		max-width: 190px;
	}
	&__icon {
		display: none;
		color: #fff;
		cursor: pointer;
	}
}

.search { 
	position: relative;
	width: 150px;
	height: 100%;
	// max-height: 43px;
	@include respond-to('1100') {
		max-width: 190px;
	}
	& input {
		width: 100%;
		height: 34px;
		border: none;
		border-bottom: 1px solid $accent;
		padding: side-values(4px 0 4px 0);
		font-size: 18px;
		font-weight: 400;
		// line-height: 22px;
		text-indent: 5px;
		background-color: transparent;
		color: $accent;
		font-style: italic;
		transition: all 0.2s ease;
		&::placeholder {
			font-size: 18px;
			font-weight: 400;
			font-style: italic;
			color: $accent;
		}
	}
	& button {
		position: absolute;
		top: 8px;
		#{$left}: 0;
		border: none;
		background: none;
		transition: all 0.2s ease;
		& i {
			color: $accent;
		}
	}
}

.social-facebook {
	position: relative;
	top: 13px;
	font-size: 17px;
	@include link_no-hover(#fff);
}

.header-mobile {
	display: none;
	height: 70px;
	// background-color: rgba($color: #000000, $alpha: 0.7);
	@include respond-to('medium') {
		display: block;
		
	}
	&__logo {
		margin-#{$right}: 15px;
		margin-top: 10px;
		float: $left;
		& > img {
			width: 200px;
			@include respond-to('medium') {
				width: 80px;
				margin-left: 9px;
				margin-top: -7px;
			}

		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			font-weight: 100;
			color: #222;
			font-size: 30px;
		}
	}
}

.main-header.scrolling {
	.header-desktop {
		height: 100px;
		background-image: none;
		background-color: #f7f7f7d9;
	}
	.header-mobile {
		@include respond-to('medium') {
			background-color: rgba(0,0,0,.8);

		}

	}
		
	.main-navi {
		// margin-#{$right}: 330px;
		@include respond-to('larger') {
			// margin-#{$right}: 270px;
		}
		@include respond-to('large') {
			// margin-#{$right}: 250px;
		}
		@include respond-to('1100') {
			// margin-#{$right}: 210px;
		}
		width: 50%;
		& > ul {
			margin-top: 28px;
			& li {
				padding-#{$left}: 28px;
				@include respond-to('larger') {
					padding-#{$left}: 15px;
				}
			}
		}
	}
	
	.category-navi {
		width: 20%;  
		// margin-#{$right}: 330px;
		& ul {
			margin-top: 8px;
			& li {
				padding-#{$left}: 28px;
				@include respond-to('huge') {
					padding-#{$left}: 28px;
					padding-right: 0; 
				}
				@include respond-to('larger') {
					padding-#{$left}: 28px;
					padding-right: 0; 
				}
				@include respond-to('large') {
					padding-#{$left}: 12px; 
				}

				
				&:last-child {
					padding-#{$left}: 0;
				}
				& a {
					font-size: 18px;
					font-weight: 300;
				}
				& ul {
					margin-top: -2px;  
				}
			}
		}
	} 
	.logo {
		// #{$right}: 111px;
		// left: auto;  
		// transform: translateX(0);
		top: 2px; 
		@include respond-to('larger') {
			top: 2px;
		}
		& img {
			max-width: 120px; 
			@include respond-to('larger') {
				max-width: 120px;
			}
			@include respond-to('1100') {
				max-width: 120px;
			}
			
			

			
		}
	}
	.sub-navi {
		width: 30%;
		// float: $left;
		// padding-top: 0px;  
		// margin-top: -30px;
		// & .find-last {
		// 	margin-#{$left}: 25px;
		// 	& .search {
		// 		display: none;
		// 	}
		// 	&__icon {
		// 		display: block;
		// 	}
		// }
		// & .social-facebook {
		// 	top: 0;
		// }
	}

	.header-mobile__tel i{
		color: #fff;
	}
	.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
		background-color: #fff;
	}


}
#head-search:focus{
	outline: none; 
}

.category-navi {
	// display: inline-block;
	// height: 100%;
	& > ul {
		// display: inline-block;
		// list-style: none;
		// //   padding:32px 190px 0 0;
		// margin: 0;
		// height: 100%;
		@include respond-to('huge') {
			// padding: 32px 20px 0 0;
		}
		& > li {
			float: right;
			// position: relative;
			height: 100%;
			&:hover {
				& > ul {
					max-height: 59vh;
    				overflow-y: scroll;
					display: block;
					opacity: 1;
					transition: 0.4s;
				}
			}
			&:last-child {
				& > span {
					opacity: 0;
					display: none;
				}
			}
			& > a {
				// padding: 2px;

				font-size: 19px;
				//   color: #002c5c;
				@include respond-to('huge') {
					font-size: 18px;
				} 

				@include respond-to('large') {
					font-size: 15px;
				}
			}
			& > ul {
				padding: 0;
				margin: 0;
				list-style: none;
				background-color: #ffffff;
				position: absolute;
				top: 35px; 
				right: 0;
				z-index: 9;
				font-size: 15px;
				opacity: 0;
				display: none;
				transition: 0.4s;
				min-width: 200px;
				text-align: right;
				//   border-left: 1px solid #3d5184;
				@include respond-to('large') {
					top: 31px;
				}
				& > li {
					&:hover {
						background-color: #f4f4f4;
					}
					border-bottom: 1px solid #f3f3f3;
					position: relative;
					& > a {
						border-bottom: 1px solid #e1e1e1;
						transition: 0.3s;
						color: #002c5c;
						padding: 4px 12px;
						display: block;
						opacity: 1;
						&:hover {
							text-decoration: none !important;
						}
					}
					& > ul {
						padding: 0;
						margin: 0;
						list-style: none;
						background-color: #ffffff;
						position: absolute;
						top: 0;
						transition: 0.4s;
						left: 200px;
						z-index: 9;
						opacity: 0;
						display: none; 
						min-width: 400px;
						text-align: center;
						& > li {
							//border-bottom: 1px solid #3a4f82;
							position: relative;
							&:hover {
								background-color: #f4f4f4;
								text-decoration: none !important;
							}
							& > a {
								&:hover {
									text-decoration: none !important;
								}
								transition: 0.3s;

								color: #002c5c;
								padding: 4px 12px;
								display: block;
								opacity: 1;
								border-bottom: 1px solid #f3f3f3;
								text-align: left;
							}
							& > ul { 
								padding: 0 20px;
								margin: 0;
								list-style: none;
								background-color: #ffffff;
								position: absolute;
								top: 0;
								transition: 0.4s;
								right: 314px;
								z-index: 9;
								opacity: 0;
								display: none;
								min-width: 315px;
								text-align: center;
								& > li {
									position: relative;
									float: right;
									width: 50%;
									&:nth-child(2n+1) {
										clear: both;
									}
									& > a {
										transition: 0.3s;
										color: #2d2a6e;
										padding: 12px 0px;
										display: block;
										opacity: 1;
										&:hover {
											color: #002c5c;
										}
									}
								}
							}
							&:hover {
								& > ul {
									transition: 0.3s;
									display: block;
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						& > ul {
							transition: 0.3s;
							display: block;
							opacity: 1;
						}
					}
				}
 
			}
		}
	}
} 
	 


.header-desktop .section-limit {
	// padding: 0 115px; 
}
.banner-item__title{
	right: 100px !important;
}
.category-navi{
	width: 20%;
}
.main-navi{
	width: 50%;
} 
.sub-navi{
	width: 30%;
}
.category-navi>ul{
	margin: 120px -30% 0;
}
.social-facebook{
	top: 4px;
}
.social-facebook img , .ftr-soc img {
    max-width: 37px;
	padding: 0 3px;
}
.main-header.scrolling .sub-navi .find-last {
	margin-top: 6px;
}
@media screen and (max-width: 1402px) {
.category-navi>ul{
	margin: 120px -80% 0;
}

}


@media screen and (max-width: 1100px) {
.category-navi>ul>li{
	margin-left: 0;
}
 
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
	height: 1px; 
}






.nav-panel-bottom__navigation{
	& > ul{
	//   padding: 0;
	//   margin: 0;
	//   list-style: none; 
	  & > li{
		// float: right;
		// border-left: 1px solid #000;
		// height: 50px;
		// &:after{
		//   content: "";
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   width: 1px;
		//   height: 100%;
		//   border-right: 1px solid #313131; 
		// }
		// & > a{
		//   font-size: 22px;
		//   color:#fff;
		//   font-weight: bold;
		//   height: 100%;
		//   display: inline-block;
		//   padding: 0 17px 0;
		//   line-height: 50px;
		// //   @include respond-to('1750'){
		// // 	font-size: 17px;
		// //   }
		// //   @include respond-to('1550'){
		// // 	padding: 0 10px;
		// //   }
		// //   @include respond-to('1100'){
		// // 	font-size: 18px;
		// //   }
		//   &.nav-panel-bottom_active{
		// 	//color: #eb1c24;
		//   }
		// }
		// &:last-child{
		//   & > a{
		// 	background-color: transparent;
		//   }
		// }
		&:hover{
			
			// border: 1px solid #fff;
			// border-bottom: none;
		  & .nav-panel-bottom__sub-navigation{
			display: block;
		  }
		  & > a{
			color: #f47724;
			border-bottom: none;
			//   border: 1px solid #fff;
			// color: #eb1c24;
			// background-color: #fff;
		  }
		}
	  }
	}
  }
  
  
.nav-panel-bottom__sub-navigation{
	display: none;
	width: calc(100% - 230px);
	min-height: 200px;
	height: auto;
	margin: 0 115px;
	// background-color: #ffe8c0;
	background-color: rgba(0,0,0,.8);
	position: absolute;
	// top: 50px;
	right: 0;
	padding: 30px 10px 30px 30px;
	// padding-top: 31px;
	-webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);
  } 
  
  .nav-panel-bottom__sub-navigation__ul{
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
	position: relative;
	z-index: 1;
	float: right;
	height: 100%;
	width: 80%;
	& > li{
	  padding-left: 40px;
	  padding-right: 20px;
	  float: right;
	  position: relative;
	  text-align: right;
	  width: 32%; 
	  @include respond-to('1550'){
		// width: 13%;
	  }
	  @include respond-to('large'){
		// width: 15%;
	  }
  
	  &:after{
  
	  }
	  & > a{
		font-size: 19px;
		font-weight: 300;
		// border-bottom: 1px solid #3c3c3c;
		color: #fff; 
		display: block; 
		position: relative;
		padding-bottom: 8px;
		padding-top: 8px;
		@include respond-to('1550'){
		  line-height: 1;
		  white-space: nowrap;
		  font-size: 18px;
		}

		@include respond-to('larger'){
			
			font-size: 15px;
		  }
		  &:before{    width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
			background-color: #e6e6e6;
			content: "";
			position: absolute;
		}
		&:after {
			height: 1px;
    bottom: 0;
    left: 50%;
    right: 50%;
    background-color: #f47724;
    content: "";
    position: absolute;
    width: 0;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
		}
		
  
	  }
	  &:hover{
		& > a{
		  color: #e28f00;
			border-bottom: none !important;
			text-decoration: none !important;
			&:after {
				   left: 0; 
					right: 0;
					width: auto;
				}
		}
	  }
	  & > ul{
		margin: 0;
		padding: 0 20px 0 10px;
		list-style: none;
		position: absolute;
		top: 40px;
		right: 0;
		z-index: 1;
		& > li{
		  margin-bottom: 7px;
		  & > a{
			font-size: 18px;
			color: #000;
			padding-bottom: 3px;
			display: inline-block;
			position: relative;
			&:after{
			  content: "";
			  position: absolute;
			  bottom: -1px;
			  right: 0;
			  left: 0;
			  margin: 0 auto;
			  width: 0%;
			  height: 1px;
			  background-color: #eb1c24;
			  @include transition(all 0.2s);
			}
			&:hover{
			  color: #eb1c24;
			  &:after{
				width: 100%;
			  }
			}
		  }
		}
	  }
	}
  }
  
  .nav-panel-bottom__sub-navigation__ul-li-border-left{
	width: 1px;
	display: inline-block;
	height: 100%;
	background-color: #ccc;
	float: right;
	position: absolute;
	z-index: 100000;
	top:0;
	right: -10px;
	bottom:0;
  }
  
  .nav-panel-bottom__sub-navigation__ul__bigger{
	width:452px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height:420px;
	overflow:hidden;
	& > li{
	  float:none;
	  width:50%;
	}
  }
  
  .nav-panel-bottom__sub-navigation__image{
	position: absolute;
	top: 30px;
	left: 30px;
	max-width: 1212px;
	width: 25%;
	height: 100%;
	@include respond-to('1550'){
  
	}
  }
  
  .nav-panel-bottom__sub-navigation__image-item{
	background-size: contain;
	width: calc(100% - 30px);
    margin-right: 30px;
	// margin: 5%;
	height: calc(100% - 60px);
	background-repeat: no-repeat;
	// .nav-panel-bottom__sub-navigation__image-item {
	// 	background-size: contain;
	// width: 90%;
	// // margin: 5%;
	// height: 90%;
	// background-repeat: no-repeat;
	// }
  }
  
  .nav-panel-bottom__sub-navigation-btn{
	display: block;
	width: 150px;
	height: 42px;
	background-color: #eb1c24;
	color: #fff;
	line-height: 42px;
	text-align: center;
	font-size: 18px;
	@include border-radius(50px);
	&:active{
	  @include btn-effect-active;
	}
	&:hover,&:visited,&:active{
	  color: #fff;
	}
  }
  
  #fixed-top.scrolling{
	& > .header-desktop{
	  height: 90px;
	  position: relative;
	  @include respond-to('huge'){
		height: 140px;
	  }
	  & > .nav-panel-top{
		height: 50px;
		padding-top: 8px;
		@include respond-to('huge'){
		  height: 90px;
		  padding-top: 10px;
		}
	  }
	  & > .nav-panel-bottom{
		height: 40px;
		@include respond-to('huge'){
		  height: 50px;
		}
		&:before{
		  content: "";
		  position: absolute;
		  bottom: 0;
		  left: 0;
		  background-repeat: no-repeat;
		  background-image: url(../images/shadow-nav2.png);
		  height: 40px;
		  width: 500px;
		  pointer-events: none;
		  @include respond-to('huge'){
			display: none;
		  }
		}
	  } 
	//   .nav-panel-bottom__navigation{
	// 	& > ul{
	// 	  & > li{
	// 		height: 40px;
	// 		@include respond-to('huge'){
	// 		  height: 50px;
	// 		}
	// 		& > a{
	// 		  line-height: 40px;
	// 		  @include respond-to('huge'){
	// 			line-height: 50px;
	// 		  }
	// 		}
	// 	  }
	// 	}
	//   }
	  .nav-panel-bottom-navigation-down>a>i{
		top: 5px;
	  }
	  & .logo{
		top: 0px;
	  }
	}
	.nav-panel-bottom-navigation-down__wrap-hamburger{
	  margin-top: 8px;
	  @include respond-to('huge'){
		margin-top: 13px;
	  }
	}
	.nav-panel-bottom__sub-navigation{
	  top: 40px;
	  @include respond-to('huge'){
		top: 50px;
	  }
	}  
  }
  
  @media screen and (max-width: 325px) {
  .header-mobile__logo{
	margin-right: 0px; 
  }
}

.main-header.scrolling .category-navi > ul li:first-child{
	padding-right: 0;  
}
.nav-panel-bottom__sub-navigation ul li:first-child{
	padding-right: 20px !important;   
}
.nav-panel-bottom__sub-navigation ul li:last-child {
	padding-left: 40px !important;   
}

.main-header.scrolling .nav-panel-bottom__sub-navigation ul li:last-child {
	padding-left: 27px !important;   
}

.main-header.scrolling .sub-navi {
	margin-left: -3px ;  
}


@media screen and (max-width: 1400px) {
	.main-header.scrolling  .nav-panel-bottom__sub-navigation ul li:first-child{
		padding-right: 0 !important;    
	}
	
	
}   
@media screen and (min-width: 1450px) {
	.main-header.scrolling .category-navi ul {
		margin: 8px 0% 0 !important;
	}
	.category-navi>ul>li {
		cursor: pointer; 
		padding: 0 9px;
	}
	
	
}

@media screen and (max-width: 1290px) {
	.main-header.scrolling .sub-navi {
		margin-left: -11px ;  
	}
}


.social-phone{
	color: #a5742f;
	font-size: 20px;
}
.social-phone:hover{
	color: #a5742f;
 
}

.fa-chevron-down{
	font-size: 17px; 
}