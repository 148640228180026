.investors {
	padding-top: 15px;
	text-align: center;
	&__title {
		position: relative;
		display: inline-block;
		padding-bottom: 8px;
		color: $title;
		font-size: 30px;
		font-weight: 900;
		line-height: 0.9;
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	
	}
	&__slider {
		padding: 85px 0;
		@include respond-to('large') {
			padding: 50px 0;
		}
		@include respond-to('medium') {
			padding: 30px 0;
		}
	}
	&__slide-item-wrap {
		display: none;
		position: relative;
		height: 160px;
		// min-width: 165px;
		line-height: 160px;
	}
	&__slide-item {
		display: inline-block;
		&:active,
		&:focus,
		&:hover {
			outline: none;
			& .img-grad {
				opacity: 0;
			}
			& .img-nograd {
				opacity: 1;
			}
		}
		&:first-child {
			display: block;
		}
		& img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			// display: inline-block;
			// margin: 0 auto;
			max-height: 100%;
			height: auto;
		}
	}
}

.img-grad {
	opacity: 1;
	transition: opacity 0.2s ease;
}
.img-nograd {
	opacity: 0;
	transition: opacity 0.2s ease;
}

#clients-slide {
	& .slick-arrow {
		width: 12px;
		height: 46px;
	}
	& .slick-prev {
		#{$right}: -15px;
		&::before {
			content: '\f054';
			font-family: "Font Awesome 5 Pro";
			color: #a5742f;
			font-size: 25px;
			// font-weight: 400;
			// content: if-rtl(url('../images/arr-right.png'), url('../images/arr-left.png'));
		}
	}
	& .slick-next {
		#{$left}: -15px;
		&::before {
			content: '\f053';
			font-family: "Font Awesome 5 Pro";
			color: #a5742f;
			font-size: 25px;
			// font-weight: 400;
			// content: if-rtl(url('../images/arr-left.png'), url('../images/arr-right.png'));
		}
	}
}
